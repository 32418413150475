.player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding: 1rem;
    background: #ffffff;
    border-radius: 1rem;
}

    .player__btn {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.6rem;
        background: #84b4d0;
        border: none;
        border-radius: 0.6rem;
        text-align: center;
    }

    .player__btn:hover {
        cursor: pointer;
    }

    .player__play svg {
        width: 1rem;
        fill: white;
    }

    .player__vol svg {
        width: 1.2rem;
        fill: white;
        stroke: white;
    }

    .player__vol-box {
        display: none;
    }
.app {
    min-height: 100vh;
    background-color: #84b4d0;
    font-family: 'Roboto', sans-serif;
}

.wrap {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
}

h1 {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 50px;
}

h2 {
    color: #ffffff;
}

p {
    color: #ffffff;
    line-height: 24px;
}

.top {
    margin-bottom: 40px;
}

    @media only screen and (min-width: 768px) {
        .top {
            display: flex;
        }
    }

    @media only screen and (min-width: 768px) {
        .top__left,
        .top__right {
            width: 50%;
        }
    }

    .logo__title {
        font-family: 'Carter One', cursive;
    }

.player {
    margin-bottom: 40px;
}

.date {
    margin-bottom: 20px;
    color: #ffffff;
    text-align: left;
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: bold;
    line-height: 30px;
}

.schedule {
    padding: 20px 0 30px;
}

@media only screen and (min-width: 768px) {
    .schedule {
        display: flex;
        justify-content: space-between;
    }

    .schedule__item {
        width: 30%;
    }
}

.border-bottom {
    border-bottom: 1px solid #ffffff;
}

.socials {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}

.social-link {
    display: block;
    margin: 0 20px;
}

    .social-link__icon {
        height: 20px;
    }
    
.soundcloud {
    margin-top: 40px;
}

    .soundcloud__iframe {
        width: 100%;
        height: 200px;
        margin-bottom: 40px;
    }


    @media only screen and (min-width: 768px) {
        .soundcloud__iframe {
            width: calc(50% - 20px);
        }

        .soundcloud__iframe--left {
            margin-right: 20px;
        }

        .soundcloud__iframe--right {
            margin-left: 20px;
        }
    }

.live {
    position: relative;
}

    .live__icon {
        position: absolute;
        width: 10px;
        height: 10px;
        left: -30px;
        top: 6px;
        background-color: red;
        border-radius: 100%;
        animation: glow 1s linear 0s infinite alternate;
    }

    .live__text {
        margin: 0;
        color: #222222;
    }

@keyframes glow {
    from {box-shadow: 0px 0px 2px red;}
    to {box-shadow: 0px 0px 5px red;}
}

iframe#1d94760020000272500123174 {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.test-link {
    display: none;
}

.shows {
}

.shows__row {
    margin: 0 -20px 50px;
    width: 100%;
    display: flex;
}

.show__img {
    width: 100%;
    height: 100%;
    border: 15px solid #fff;
    box-sizing: border-box;
}

.flip-card {
    background-color: transparent;
    height: 300px;
    width: calc((100% / 3) - 40px);
    margin: 0 20px 0;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
    .flip-card-back {
        background-color: white;
        transform: rotateY(180deg);
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
    }
    .flip-card-back h2,
    .flip-card-back p {
        color: black;
        font-size: 20px;
        margin: 0 0 10px;
    }
